import styled from "styled-components";

export const StyledMenuFixed = styled.div`
  width: 100vw;
  @media (min-width: 1000px) {
    margin: 0 auto;
  }
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
`;

export const StyledMenu = styled.div`
  width: 100vw;
  @media (min-width: 1000px) {
    width: 600px;
    margin: 0 auto;
  }
  inset: 0;
  margin: auto;
  position: relative;
`;

export const StyledMenuBackGround = styled.img`
  width: 100vw;
  position: fixed;
  top: -17vw;
  margin: 0 auto;
  @media (min-width: 1000px) {
    width: 605px;
    margin-left: -7.5px;
    top: -2px;
  }
  transition: 0.5s;
`;

export const StyledMenuBottom = styled.div`
  position: absolute;
  width: 100vw;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  top: 2.7vw;
  left: 0.5vw;
  @media (min-width: 1000px) {
    width: 555.1px;
    margin: 0 auto;
    top: 115px;
    left: 22px;
  }
  transition: 0.5s;
`;

export const StyledLogo = styled.img`
  width: calc(2 * 8vw);
  height: calc(1.24 * 8vw);
  margin-right: 1vw;
  @media (min-width: 1000px) {
    margin-right: 4px;
    width: calc(200px * 0.5);
    height: calc(124px * 0.5);
  }
  transition: 0.5s;
`;

export const StyledMenuBtnLeft = styled.img`
  width: calc(1.6 * 16vw);
  height: calc(0.62 * 16vw);
  margin-right: 1vw;
  @media (min-width: 1000px) {
    width: calc(160px * 0.96);
    height: calc(62px * 0.96);
    margin-right: 4px;
  }
  transition: 0.5s;
`;

export const StyledMenuBtnRight = styled.img`
  width: calc(1.6 * 16vw);
  height: calc(0.62 * 16vw);
  margin-right: 0.5vw;
  @media (min-width: 1000px) {
    width: calc(160px * 0.96);
    height: calc(62px * 0.96);
    margin-right: 3px;
  }
  transition: 0.5s;
`;

export const StyledStepCount = styled.img`
  width: calc(0.78 * 8vw);
  height: calc(1.22 * 8vw);
  margin-right: 1vw;
  @media (min-width: 1000px) {
    width: calc(78px * 0.49);
    height: calc(122px * 0.49);
    margin-right: 5px;
  }
  transition: 0.5s;
`;

export const StyledMenuBtnDown = styled.img`
  width: calc(0.54 * 16vw);
  height: calc(0.62 * 16vw);
  margin-bottom: 6px;
  margin-left: -2px;
  @media (min-width: 1000px) {
    width: calc(54px * 1);
    height: calc(62px * 1);
  }
  transition: 0.5s;
`;

export const StyledMenuBtnUp = styled.img`
  width: calc(0.54 * 16vw);
  height: calc(0.62 * 16vw);
  margin-bottom: 6px;
  @media (min-width: 1000px) {
    width: calc(54px * 1);
    height: calc(62px * 1);
  }
  transition: 0.5s;
`;

export const NewUrlButton = styled.div``;
