import React from "react";
import { useCNPCImage } from "../hooks/useCNPCImage";
import { useCNPCShelves } from "../hooks/useCNPCShelves";
import Header from "./Header";
import { StyledShelves } from "./styledComponents";

function StyledShelvesComponent({
  setNowUrl,
  nowUrl,
  setBtnLeft,
  setBtnRight,
  btnLeft,
  btnRight,
}) {
  const imgSrc = useCNPCImage();
  const { displayCNPC, displayCNPCPages } = useCNPCShelves(imgSrc);

  return (
    <>
      <Header
        setBtnLeft={setBtnLeft}
        setBtnRight={setBtnRight}
        btnLeft={btnLeft}
        btnRight={btnRight}
        nowUrl={nowUrl}
        setNowUrl={setNowUrl}
      />
      <StyledShelves>
        {displayCNPC}
        {displayCNPCPages}
      </StyledShelves>
    </>
  );
}

export default StyledShelvesComponent;
