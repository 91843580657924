import styled from "styled-components";

export const StyledShelves = styled.div`
  margin-top: 15vw;
  margin-bottom: 15vw;
  @media (min-width: 1000px) {
    margin-top: 190px;
  }
`;

export const StyledShelf = styled.div`
  position: relative;
  width: 100vw;
  height: 36vw;
  @media (min-width: 1000px) {
    width: 600px;
    margin: 0 auto;
    height: 210px;
  }
  transition: 0.5s;
`;

export const StyledShelfImg = styled.img`
  position: absolute;
  width: 100vw;
  @media (min-width: 1000px) {
    width: 600px;
  }
`;

export const Cnpcs = styled.div`
  display: flex;
  width: 100vw;
  padding-left: 0vw;
  padding-top: 3.2vw;
  @media (min-width: 1000px) {
    width: 600px;
    padding-left: 0px;
    padding-top: 18px;
  }
`;

export const StyledImg = styled.img`
  width: 19.2vw;
  @media (min-width: 1000px) {
    width: 115px;
  }
  margin: 0px -0.4%;
  transition: 0.5s;
`;

export const StyledContainer = styled.div`
  background-image: url("/config/images/bg_mobile.jpg");
  min-width: 100vw;
  background-size: contain;
  background-repeat: repeat;
  background-attachment: fixed;
  padding-bottom: calc(100vh - 50vw);
  @media (min-width: 1000px) {
    min-height: 100vh;
    padding-bottom: calc(100vh - 300px);
  }
`;

export const WindowImg = styled.div`
  display: none;
  @media screen and (min-width: 1000px) {
    position: fixed;
    width: 100vw;
    height: 1104px;
    background-size: 1442px 1309px;
    background-position: top center;
    background-repeat: no-repeat;
    background-image: none;
    display: block;
    background-image: url("/config/images/pc_window_bg.png");
  }
`;

export const BackgroundImg = styled.div`
  display: none;
  background-image: none;
  @media screen and (min-width: 1000px) {
    position: fixed;
    width: 100vw;
    background-size: cover;
    background-position: top center;
    background-repeat: repeat;
    display: block;
    height: 1104px;
    background-image: url("/config/images/pc_bg.jpg");
  }
`;

export const OverlapImg = styled.div`
  display: none;
  @media screen and (min-width: 1000px) {
    position: fixed;
    width: 100vw;
    height: calc(100vh - 1080px);
    top: 1102px;
    z-index: 998;
    background-size: cover;
    background-position: top center;
    background-repeat: repeat;
    background-image: none;
    display: block;
    background-image: url("/config/images/pc_bg.jpg");
  }
`;

export const OverlapWindow = styled.div`
  display: none;
  @media screen and (min-width: 1000px) {
    position: fixed;
    width: 100vw;
    height: calc(100vh - 1080px);
    top: 1102px;
    z-index: 999;
    background-size: 1442px 1309px;
    background-position: center -1102px;
    background-repeat: no-repeat;
    background-image: none;
    display: block;
    background-image: url("/config/images/pc_window_bg.png");
  }
`;
