import { useState, useEffect, useCallback } from "react";

export const useSortCNPC = (imgSrc) => {
  const [sorted, setSorted] = useState([]);

  const assignIfNotGoldOrRainbow = useCallback((sortedItems, index, item) => {
    const updatedItems = [...sortedItems];
    if (
      !updatedItems[index] ||
      typeof updatedItems[index]?.charactor !== "string"
    ) {
      updatedItems[index] = item;
    } else if (
      !updatedItems[index]?.charactor.includes("gold") &&
      !updatedItems[index]?.charactor.includes("rainbow")
    ) {
      updatedItems[index] = item;
    } else if (
      updatedItems[index]?.charactor.includes("gold") &&
      item?.charactor.includes("rainbow")
    ) {
      updatedItems[index] = item;
    }
    return updatedItems;
  }, []);

  const sortCNPC = useCallback(() => {
    const PRAY_TO_INDEX = {
      pray_to_safe: 0,
      health_and_longevity: 1,
      business_success: 2,
      praying_for_passing: 3,
      household_harmony: 4,
      matchmaking: 5,
      calamity_prevention: 6,
      good_health: 7,
      a_lot_of_customers: 8,
      good_fortune_and_calamity_prevention: 9,
      "success of competition": 10,
      academi_achievement: 11,
      praying_for_being_hired: 12,
      traffic_safety: 13,
      economic_success: 14,
      fulfillment_in_love: 15,
      safe_delivery: 16,
      safe_to_children: 17,
      pets_happiness: 18,
      wish_fulfillment: 19,
      explosive_profits: 20,
      avoid_the_crash: 21,
      scam_prevention: 22,
      gox_prevention: 23,
      hacking_prevention: 24,
      prevent_wallet: 25,
      falling_asleep_prevention: 26,
      matchmaking_cnp: 27,
      get_al: 28,
      get_giveaway: 29,
      leelee: 30,
      luna: 31,
      narukami: 32,
      orochi: 33,
      mitama: 34,
      mint_sucsess: 35,
      project_sucsess: 36,
    };

    const INDEX_TO_PRAY = Object.fromEntries(
      Object.entries(PRAY_TO_INDEX).map(([pray, number]) => [number, pray])
    );

    let sortedItems = [];
    let url =
      "https://opensea.io/ja/collection/cnp-charm-official?search[stringTraits][0][name]=pray&search[stringTraits][0][values][0]=";
    for (let i = 0; i < 45; i++) {
      if (i === 10) {
        sortedItems[10] = url + "success%20of%20competition";
      } else {
        sortedItems[i] = url + (INDEX_TO_PRAY[i] || 0);
      }
    }

    for (let i = 0; i < imgSrc.length; i++) {
      const index = PRAY_TO_INDEX[imgSrc[i]?.pray];
      if (index !== undefined) {
        sortedItems = assignIfNotGoldOrRainbow(sortedItems, index, imgSrc[i]);
      }
    }
    setSorted(sortedItems);
  }, [imgSrc, assignIfNotGoldOrRainbow]);

  useEffect(() => {
    if (imgSrc.length > 0) {
      sortCNPC();
    }
  }, [imgSrc, sortCNPC]);

  return sorted;
};
