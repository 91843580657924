import { useState, useEffect, useCallback } from "react";
import {
  StyledShelf,
  StyledShelfImg,
  Cnpcs,
  StyledImg,
} from "../components/styledComponents";

const ITEMS_PER_SHELF = 5;
const SHELVES_PER_PAGE = 4;

const CNPCImage = ({ step, pageNumber, index, src, isTransparent }) => {
  const key = isTransparent
    ? `transparent${index}`
    : `cnpc${step * pageNumber + index}`;
  const alt = isTransparent ? "transparent" : `cnpc${step + index}`;

  return <StyledImg key={key} alt={alt} src={src} />;
};

const CNPCShelf = ({ pageNumber, step, children }) => {
  const id = (pageNumber - 1) * SHELVES_PER_PAGE + step;
  const key = `shelf-${id}`;

  return (
    <div id={id} className="element-with-id" key={key}>
      <StyledShelf key={key}>
        <StyledShelfImg src={`/config/images/shelf0${step}.png`} />
        <Cnpcs
          style={{
            position: "absolute",
            justifyContent: "center",
          }}
        >
          {children}
        </Cnpcs>
      </StyledShelf>
    </div>
  );
};

export const useCNPCShelves = (imgSrc) => {
  const [displayCNPC, setDisplayCNPC] = useState([]);
  const [displayCNPCPages, setDisplayCNPCPages] = useState([]);

  const generateCNPCShelves = useCallback(
    (imgSrc, pageNumber) => {
      let displayCNPCShelf = [];
      let loopCount =
        pageNumber === 1
          ? SHELVES_PER_PAGE
          : Math.ceil(
              (imgSrc.length -
                (pageNumber - 1) * ITEMS_PER_SHELF * SHELVES_PER_PAGE) /
                ITEMS_PER_SHELF
            );
      loopCount = loopCount > SHELVES_PER_PAGE ? SHELVES_PER_PAGE : loopCount;

      if (imgSrc.length > 0) {
        for (let step = 1; step <= loopCount; step++) {
          let displayCNPCArray = [];
          let leftCount =
            imgSrc.length -
            (pageNumber - 1) * ITEMS_PER_SHELF * SHELVES_PER_PAGE -
            (step - 1) * ITEMS_PER_SHELF;
          let itemsCount =
            leftCount >= ITEMS_PER_SHELF
              ? ITEMS_PER_SHELF
              : leftCount > 0
              ? leftCount
              : 0;

          for (let i = 1; i <= itemsCount; i++) {
            const srcIndex =
              ITEMS_PER_SHELF * (step - 1) +
              i -
              1 +
              (pageNumber - 1) * ITEMS_PER_SHELF * SHELVES_PER_PAGE;
            const src = imgSrc[srcIndex].src;
            displayCNPCArray.push(
              <CNPCImage
                step={step}
                pageNumber={pageNumber}
                index={i}
                src={src}
              />
            );
          }

          for (let i = itemsCount + 1; i <= ITEMS_PER_SHELF; i++) {
            displayCNPCArray.push(
              <CNPCImage
                step={step}
                pageNumber={pageNumber}
                index={i}
                src={"/config/images/transparent.png"}
                isTransparent={true}
              />
            );
          }

          displayCNPCShelf.push(
            <CNPCShelf pageNumber={pageNumber} step={step}>
              {displayCNPCArray}
            </CNPCShelf>
          );
        }
      }
      return (
        <div key={"shelf-container-" + pageNumber} className="shelf">
          {displayCNPCShelf}
        </div>
      );
    },
    [imgSrc]
  );

  useEffect(() => {
    if (imgSrc.length > 0) {
      setDisplayCNPC(generateCNPCShelves(imgSrc, 1));

      const shelves = [];
      for (
        let pageNumber = 2;
        pageNumber <
        Math.ceil(imgSrc.length / (ITEMS_PER_SHELF * SHELVES_PER_PAGE)) + 1;
        pageNumber++
      ) {
        shelves.push(generateCNPCShelves(imgSrc, pageNumber));
      }
      setDisplayCNPCPages(shelves);
    }
  }, [imgSrc]);

  return { displayCNPC, displayCNPCPages };
};
