import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  StyledMenuFixed,
  StyledMenu,
  StyledMenuBackGround,
  StyledMenuBottom,
  StyledLogo,
  StyledMenuBtnLeft,
  StyledMenuBtnRight,
  StyledStepCount,
  StyledMenuBtnDown,
  StyledMenuBtnUp,
  NewUrlButton,
} from "../components/styledHeaderComponent";

function Header({
  setNowUrl,
  nowUrl,
  setBtnLeft,
  setBtnRight,
  btnLeft,
  btnRight,
}) {
  const navigate = useNavigate();

  const [logoSrc, setLogoSrc] = useState("/config/images/btn_top_normal.png");
  const [stepCountSrc, setStepCountSrc] = useState(
    "/config/images/page_${nowStep}.png"
  );
  const [btnDownSrc, setBtnDownSrc] = useState(
    "/config/images/btn_pagedown_normal.png"
  );
  const [btnUpSrc, setBtnUpSrc] = useState(
    "/config/images/btn_pageup_normal.png"
  );
  const [nextStep, setNextStep] = useState(1);
  const [prevStep, setPrevStep] = useState(1);
  const [nowStep, setNowStep] = useState(1);

  const newUrl = () => {
    setNowUrl(nowUrl);
  };

  const nextStepBtn = (e, sectionId) => {
    e.preventDefault();
    let stepCountSrc =
      document.getElementsByClassName("element-with-id").length;
    if (nextStep < stepCountSrc) {
      setNextStep(nextStep + 1);
      setPrevStep(prevStep + 1);
      setNowStep(prevStep + 1);
      window.location.hash = sectionId + 1;
    }
  };

  const prevStepBtn = (e, sectionId) => {
    e.preventDefault();
    if (prevStep > 1) {
      setNextStep(nextStep - 1);
      setPrevStep(prevStep - 1);
      setNowStep(prevStep - 1);
      window.location.hash = sectionId - 1;
      if (prevStep - 1 == 0) {
        setNowStep(1);
        window.location.hash = 1;
      }
    }
  };

  const leftBtnHandleClick = () => {
    navigate("/");
    setBtnLeft("/config/images/btn_menu01_down.png");
    setBtnRight("/config/images/btn_menu02_normal.png");
  };

  const rightBtnHandleClick = () => {
    navigate("/pray-ordered");
    setBtnLeft("/config/images/btn_menu01_normal.png");
    setBtnRight("/config/images/btn_menu02_down.png");
  };

  useEffect(() => {
    setStepCountSrc(`/config/images/page_${nowStep}.png`);
  }, [nowStep]);

  useEffect(() => {
    newUrl();
  }, [newUrl]);

  return (
    <StyledMenuFixed>
      <StyledMenu>
        <StyledMenuBackGround
          src={"/config/images/menu_base.png"}
        ></StyledMenuBackGround>
        <StyledMenuBottom>
          <a href="/" rel="noopener noreferrer">
            <StyledLogo
              src={logoSrc}
              onMouseDown={() => setLogoSrc("/config/images/btn_top_down.png")}
              onMouseUp={() => setLogoSrc("/config/images/btn_top_normal.png")}
              onMouseLeave={() =>
                setLogoSrc("/config/images/btn_top_normal.png")
              }
              onTouchStart={() => setLogoSrc("/config/images/btn_top_down.png")}
              onTouchEnd={() => setLogoSrc("/config/images/btn_top_normal.png")}
            ></StyledLogo>
          </a>
          <StyledMenuBtnLeft
            src={btnLeft}
            onMouseDown={() => setBtnLeft("/config/images/btn_menu01_down.png")}
            onMouseUp={() => setBtnLeft("/config/images/btn_menu01_normal.png")}
            onTouchStart={() =>
              setBtnLeft("/config/images/btn_menu01_down.png")
            }
            onTouchEnd={() =>
              setBtnLeft("/config/images/btn_menu01_normal.png")
            }
            onClick={leftBtnHandleClick}
          ></StyledMenuBtnLeft>
          <StyledMenuBtnRight
            src={btnRight}
            onMouseDown={() =>
              setBtnRight("/config/images/btn_menu02_down.png")
            }
            onMouseUp={() =>
              setBtnRight("/config/images/btn_menu02_normal.png")
            }
            onTouchStart={() =>
              setBtnRight("/config/images/btn_menu02_down.png")
            }
            onTouchEnd={() =>
              setBtnRight("/config/images/btn_menu02_normal.png")
            }
            onClick={rightBtnHandleClick}
          ></StyledMenuBtnRight>
          <StyledStepCount src={stepCountSrc} key={nowStep}></StyledStepCount>
          <NewUrlButton onClick={(e) => nextStepBtn(e, nextStep)}>
            <a>
              <StyledMenuBtnDown
                src={btnDownSrc}
                onMouseDown={() => {
                  setBtnDownSrc("/config/images/btn_pagedown_down.png");
                  setStepCountSrc("/config/images/page_" + nowStep + ".png");
                }}
                onMouseUp={() => {
                  setBtnDownSrc("/config/images/btn_pagedown_normal.png");
                  setStepCountSrc("/config/images/page_" + nowStep + ".png");
                }}
                onMouseLeave={() => {
                  setBtnDownSrc("/config/images/btn_pagedown_normal.png");
                  setStepCountSrc("/config/images/page_" + nowStep + ".png");
                }}
                onTouchStart={() => {
                  setBtnDownSrc("/config/images/btn_pagedown_down.png");
                  setStepCountSrc("/config/images/page_" + nowStep + ".png");
                }}
                onTouchEnd={() => {
                  setBtnDownSrc("/config/images/btn_pagedown_normal.png");
                  setStepCountSrc("/config/images/page_" + nowStep + ".png");
                }}
              ></StyledMenuBtnDown>
            </a>
          </NewUrlButton>
          <NewUrlButton onClick={(e) => prevStepBtn(e, prevStep)}>
            <a>
              <StyledMenuBtnUp
                src={btnUpSrc}
                onMouseDown={() => {
                  setBtnUpSrc("/config/images/btn_pageup_down.png");
                  setStepCountSrc("/config/images/page_" + nowStep + ".png");
                }}
                onMouseUp={() => {
                  setBtnUpSrc("/config/images/btn_pageup_normal.png");
                  setStepCountSrc("/config/images/page_" + nowStep + ".png");
                }}
                onMouseLeave={() => {
                  setBtnUpSrc("/config/images/btn_pageup_normal.png");
                  setStepCountSrc("/config/images/page_" + nowStep + ".png");
                }}
                onTouchStart={() => {
                  setBtnUpSrc("/config/images/btn_pageup_down.png");
                  setStepCountSrc("/config/images/page_" + nowStep + ".png");
                }}
                onTouchEnd={() => {
                  setBtnUpSrc("/config/images/btn_pageup_normal.png");
                  setStepCountSrc("/config/images/page_" + nowStep + ".png");
                }}
              ></StyledMenuBtnUp>
            </a>
          </NewUrlButton>
        </StyledMenuBottom>
      </StyledMenu>
    </StyledMenuFixed>
  );
}

export default Header;
