import { connect } from "../redux/blockchain/blockchainActions";
import * as s from "../styles/globalStyles";
import {
  ResponsiveWrapper,
  StyledContainer,
  LoginTopFlex,
  LoginTopLeftImg,
  LoginTopRightImg,
  LoginTopCenterImg,
  LoginClosedWindow,
} from "../components/styledBeforeConnectComponent";

export const BeforeConnect = ({
  dispatch,
  blockchain: { errorMsg },
  getData,
}) => {
  const handleClick = () => {
    dispatch(connect());
    getData();
  };

  return (
    <StyledContainer>
      <ResponsiveWrapper>
        <s.Container
          jc={"center"}
          fd={"row"}
          fw={"wrap"}
          bgContain={"cover"}
          bgRepeat={"no-repeat"}
          style={{
            display: "block",
            paddingRight: 0,
            paddingLeft: 0,
          }}
        >
          <s.Container ai={"center"} jc={"center"}>
            <LoginTopCenterImg src="config/images/login_title.png"></LoginTopCenterImg>
            <LoginTopFlex>
              <LoginTopLeftImg src="config/images/login_left.png"></LoginTopLeftImg>
              <LoginTopRightImg src="config/images/login_right.png"></LoginTopRightImg>
            </LoginTopFlex>
            <LoginClosedWindow src="config/images/login_window.png"></LoginClosedWindow>
            <s.StyledButton onClick={handleClick}>connect</s.StyledButton>
            {errorMsg !== "" && (
              <>
                <s.SpacerSmall />
                <s.TextDescription
                  style={{
                    textAlign: "center",
                    color: "var(--accent-text)",
                  }}
                >
                  {errorMsg}
                </s.TextDescription>
              </>
            )}
          </s.Container>
        </s.Container>
      </ResponsiveWrapper>
    </StyledContainer>
  );
};
