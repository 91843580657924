import React, { useState, useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchData } from "./redux/data/dataActions";
import * as s from "./styles/globalStyles";
import StyledShelvesComponent from "./components/StyledShelvesComponent";
import StyledPrayOrderShelvesComponent from "./components/StyledPrayOrderShelvesComponent";
import {
  StyledContainer,
  WindowImg,
  BackgroundImg,
  OverlapImg,
  OverlapWindow,
} from "./components/styledComponents";
import { BeforeConnect } from "./components/BeforeConnect";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

function App() {
  const dispatch = useDispatch();
  const { account, smartContract } = useSelector((state) => state.blockchain);
  const isLoading = useSelector((state) => state.data.loading);

  const [btnLeft, setBtnLeft] = useState("/config/images/btn_menu01_down.png");
  const [btnRight, setBtnRight] = useState(
    "/config/images/btn_menu02_normal.png"
  );
  const [nowUrl, setNowUrl] = useState("/");

  const getData = useCallback(() => {
    if (account !== "" && smartContract !== null) {
      dispatch(fetchData());
    }
  }, [account, smartContract, dispatch]);

  useEffect(() => {
    getData();
  }, [getData]);

  if (account === "" || smartContract === null) {
    return (
      <s.Screen>
        <s.Container flex={1} ai={"center"}>
          <BeforeConnect
            getData={getData}
            blockchain={{ account, smartContract }}
            dispatch={dispatch}
          />
        </s.Container>
      </s.Screen>
    );
  }

  if (isLoading) {
    return (
      <s.Screen>
        <s.Container flex={1} ai={"center"}>
          <>
            読み込み中・・・
            <br />
            Now Loading...
          </>
        </s.Container>
      </s.Screen>
    );
  }

  return (
    <s.Screen>
      <s.Container flex={1} ai={"center"}>
        <StyledContainer>
          <Router>
            <BackgroundImg></BackgroundImg>
            <OverlapImg></OverlapImg>
            <WindowImg></WindowImg>
            <OverlapWindow></OverlapWindow>
            <Routes>
              <Route
                path="/*"
                element={
                  <StyledShelvesComponent
                    btnLeft={btnLeft}
                    setBtnLeft={setBtnLeft}
                    btnRight={btnRight}
                    setBtnRight={setBtnRight}
                    setNowUrl={setNowUrl}
                    nowUrl={"/"}
                  />
                }
              />
              <Route
                path="/pray-ordered/*"
                element={
                  <StyledPrayOrderShelvesComponent
                    btnLeft={btnLeft}
                    setBtnLeft={setBtnLeft}
                    btnRight={btnRight}
                    setBtnRight={setBtnRight}
                    setNowUrl={setNowUrl}
                    nowUrl={"/pray-ordered/"}
                  />
                }
              />
            </Routes>
          </Router>
        </StyledContainer>
      </s.Container>
    </s.Screen>
  );
}

export default App;
