import styled from "styled-components";

export const ResponsiveWrapper = styled.div`
  display: block;
  flex: 1;
  flex-direction: column;
  justify-content: stretched;
  align-items: stretched;
  width: 100%;
`;

export const StyledContainer = styled.div`
  background-image: url("/config/images/pc_bg.jpg");
  min-width: 100vw;
  background-size: contain;
  background-repeat: repeat;
  min-height: calc(100vh);
  padding-bottom: 10px;
  @media (min-width: 1000px) {
    min-height: 100vh;
  }
`;

export const LoginTopFlex = styled.div`
  display: flex;
  width: 100vw;
  justify-content: space-between;
  margin-top: 2.5vw;
  @media (min-width: 1000px) {
    margin-top: 10px;
  }
`;

export const LoginTopLeftImg = styled.img`
  width: calc(2.9 * 7.8vw);
  height: calc(2 * 7.8vw);
  max-width: calc(290px * 0.7);
  max-height: calc(200px * 0.7);
`;

export const LoginTopRightImg = styled.img`
  width: calc(3.28 * 7.8vw);
  height: calc(2 * 7.8vw);
  max-width: calc(328px * 0.7);
  max-height: calc(200px * 0.7);
`;

export const LoginTopCenterImg = styled.img`
  width: calc(4.5 * 7.8vw);
  height: calc(2 * 7.8vw);
  max-width: calc(450px * 0.5);
  max-height: calc(200px * 0.5);
  position: absolute;
  top: 2vw;
`;

export const LoginClosedWindow = styled.img`
  width: calc(10.88 * 7.5vw);
  height: calc(11.06 * 7.5vw);
  max-width: calc(1088px * 0.5);
  max-height: calc(1106px * 0.5);
  margin-top: 10px;
  margin-bottom: 10px;
`;
