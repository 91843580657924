import { useState, useEffect, useCallback } from "react";
import { useSelector } from "react-redux";

export const useCNPCImage = () => {
  const blockchain = useSelector((state) => state.blockchain);
  const [imgSrc, setImgSrc] = useState([]);

  const getCNPCImgSrc = useCallback(async (account) => {
    try {
      const response = await fetch(
        "https://us-central1-first-project-4fca7.cloudfunctions.net/app?address=" +
          account,
        {
          headers: {
            mode: "no-cors",
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        }
      );
      const result = await response.json();
      let imgSrcLists = JSON.parse(result.array.replaceAll(/'/g, ""));
      setImgSrc(imgSrcLists);
    } catch (error) {
      console.error("通信に失敗しました", error);
    }
  }, []);

  useEffect(() => {
    if (blockchain.account && blockchain.smartContract) {
      getCNPCImgSrc(blockchain.account);
    }
  }, [blockchain.account, blockchain.smartContract, getCNPCImgSrc]);

  return imgSrc;
};
