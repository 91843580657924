import { useEffect, useState, useMemo } from "react";
import { StyledShelf, StyledShelfImg, Cnpcs, StyledImg } from "../components/styledComponents";
import { useSortCNPC } from "../hooks/useSortCNPC";

const CNPCImage = ({ src, pray, step, index, url }) => {
  const key = `cnpc${step}${index}`;

  if (pray) {
    return <StyledImg key={key} alt={`${key} ${pray}`} src={src} />;
  }

  if (index < 37) {
    return (
      <a href={url} target="_blank" rel="noopener noreferrer">
        <StyledImg key={`transparent${step}${index}`} alt="transparent" src="/config/images/transparent.png" />
      </a>
    );
  }

  return <StyledImg key={`transparent${step}${index}`} alt="transparent" src="/config/images/transparent.png" />;
};

const CNPCShelf = ({ children, shelfNumber }) => (
  <StyledShelf key={shelfNumber}>
    <StyledShelfImg src={`/config/images/pray-order-shelf0${shelfNumber}.png`} />
    <Cnpcs style={{ position: "absolute", justifyContent: "center" }}>{children}</Cnpcs>
  </StyledShelf>
);

export const useCNPCPrayOrderShelves = (imgSrc) => {
  const [displayCNPCPages, setDisplayCNPCPages] = useState([]);
  const sorted = useSortCNPC(imgSrc);

  const generateCNPCPrayOrderShelves = useMemo(() => {
    let displayCNPCShelf = [];
    const loopCount = 8;
    const pageNumber = 1;

    if (imgSrc.length > 0) {
      for (let step = 1; step <= loopCount; step++) {
        let displayCNPCArray = [];
        for (let i = 1; i <= 5; i++) {
          let count = i - 1 + (step - 1) * 5;
          const { src, pray } = sorted[count] || {};
          const item = sorted[count] || {};
          displayCNPCArray.push(
            <CNPCImage key={`cnpc-image-${count}`} src={src} pray={pray} step={step} index={i} url={item} />
          );
        }
        const shelfNumber = step + (pageNumber - 1) * 4;
        displayCNPCShelf.push(
          <div id={shelfNumber} className="element-with-id" key={`shelf-${shelfNumber}`}>
            <CNPCShelf shelfNumber={shelfNumber}>{displayCNPCArray}</CNPCShelf>
          </div>
        );
      }
    }
    return <div key={`shelf-container-${pageNumber}`} className="shelf">{displayCNPCShelf}</div>;
  }, [imgSrc, sorted]);

  useEffect(() => {
    setDisplayCNPCPages([generateCNPCPrayOrderShelves]);
  }, [generateCNPCPrayOrderShelves]);

  return { displayCNPCPages };
};
